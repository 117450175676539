import React from 'react';

const RootIndex = () => {
  React.useEffect(() => {
    window.location = 'https://bold.co';
  }, []);

  return null;
};

export default RootIndex;
